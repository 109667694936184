import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const PrivacyPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="howtoredeem-us-popup">
      <p style={{ textAlign: "left" }}>

        <p dir="ltr" style={{ fontWeight: "bold" }}>
          FREQUENTLY ASKED QUESTIONS (FAQs)
        </p>
        <p><strong>
          1. What is “Go for Freedom Gold Offer 2024” about?</strong>
        </p>
        <p>
          It is designed to reward customers purchasing eligible Freedom Oil products.
          Upon purchasing the specified SKUs, customers stand a chance to win daily gold
          coin rewards,
        </p>
        <p>
          with bumper rewards available at the end of the campaign.
        </p>
        <p><strong>
          2. Is this promotion available for all Freedom Oil products?</strong>
        </p>
        
        <p>
          No, the promotion is only applicable to specific Freedom Oil products.
          Customers must purchase 2 Freedom Oil 1ltr Pouches to participate. The eligible SKUs will
          be listed during the campaign period.
        </p>
        
        <p>
        <strong>3. What is the program period?</strong>
        </p>
        
        <p>
          The program will run for 45 days, from 1st October 2024 to 14th November
          2024.
        </p>
        
        <p>
          Participation outside this period will not be considered.
        </p>
        
        <p>
        <strong>4. How do I participate in this program?</strong>
        </p>
        
        <p>
          After purchasing eligible Freedom Oil products, customers will receive a
          scratch card from the retailer.
        </p>
        
        <p>
          Scan the QR code on the scratch card, which will redirect you to the program
          microsite.
        </p>
        
        <p>
          Enter your mobile number, name, city, and state, then verify the details via
          OTP.
        </p>
        
        <p>
          Once registered, you will receive a confirmation message. If selected as a
          winner, you will be notified.
        </p>
        
        <p>
        <strong> 5. How many times can a customer participate using the same mobile number?</strong>
        </p>
        
        <p>
          Each mobile number can participate 8 times in a month during the program
          period but can only win once.
        </p>
        
        <p>
        <strong>6. Is the promotion valid throughout India?</strong>
        </p>
        
        <p>
          No, the promotion is valid only in the states of Andhra Pradesh, Telangana,
          Karnataka, Odisha, and Chhattisgarh.
        </p>
        
        <p>
        <strong>7. Can I participate in the program on weekends or public holidays?</strong>
        </p>
        
        <p>
          Yes, you can participate any day during the program period, including
          weekends and public holidays.
        </p>
        
        <p>
        <strong>8. What should I do if I encounter a technical issue while registering?</strong>
        </p>
        
        <p>
          If you face any issues during registration on the microsite, please contact
          customer support at 08069261940 or email Contactus@gefindia.net
        </p>
        
        <p>
        <strong>9. What happens if I accidentally enter incorrect details during
          registration?</strong>
        </p>
        
        <p>
          Ensure all details, especially your mobile number, are correct. Incorrect
          details may disqualify you from participating in or redeeming the reward.
        </p>
        
        <p>
        <strong>10. Can a customer win multiple times during the promotion?</strong>
        </p>
        
        <p>
          No, each customer can win only once during the entire promotion period, even
          if they participate multiple times.
        </p>
        
        <p>
        <strong>11. What happens if I enter incorrect details when redeeming my reward?</strong>
        </p>
        <p>
          Ensure all details, especially your address for gold coin delivery, are
          correct. If incorrect
        </p>
        
        <p>
          details are provided, the reward may not be delivered, and a replacement
          winner will be selected.
        </p>
        
        <p>
        <strong>12. What should I do if I have a query about the program?</strong>
        </p>
        
        <p>
          For any questions or concerns regarding the program, contact customer
          support at 08069261940 or email Contactus@gefindia.net
        </p>
      </p>
    </Popup>
  );
};

export default PrivacyPopup;
